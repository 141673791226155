<script>
import AuthService from "@/api/services/auth.service.js";
import appConfig from "@/app.config";
import i18n from "@/i18n";
import Layout from "@/router/layouts/auth";
import { getErrorMessageByErrorCode } from "@/shared/util/helpers.js";
import { authMethods } from "@/state/helpers";
import store from "@/state/store";
import cookie from "vue-cookies";
import { required } from "vuelidate/lib/validators";
import { convertToSeconds } from "../../shared/util/helpers";

/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English"
        },
        {
          flag: require("@/assets/images/flags/germany.jpg"),
          language: "de",
          title: "Deutsch"
        }
      ],
      lan: i18n.locale,
      email: "",
      password: "",
      companyId: null,
      userToken: null,
      userTokenExpiration: null,
      authError: null,
      userAccess: null,
      tryingToLogIn: false,
      submit: false,
      logError: null,
      isLoginError: false,
      loginSuccess: false,
      dismissSecsSuccess: 5,
      dismissCountDownSuccess: 0
    };
  },
  validations: {
    email: { required },
    password: { required }
  },
  mounted() {
    const companyActivationLink = this.$route.query.activate;
    if (companyActivationLink) {
      this.dismissCountDownSuccess = this.dismissSecsSuccess;
    }
  },
  methods: {
    ...authMethods,
    // Try to log the user in with the username
    // and password they provided.
    clickLoginSubmit() {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();

      // if validation success
      if (this.$v.$invalid) {
        this.logError = this.$t("login.alert.validation");
        this.isLoginError = true;
      } else {
        this.tryToLogIn();
      }
    },
    async tryToLogIn() {
      this.tryingToLogIn = true;

      // activate company on 1st time login
      this.companyId = this.$route.query.activate;

      // Reset the authError if it existed.
      this.logError = null;
      const response = await AuthService.getLoginToken(
        this.email.trim(),
        this.password,
        this.companyId
      );

      if (response?.accessToken) {
        this.userToken = response.accessToken;
        this.userTokenExpiration = response.expiresIn;
        this.userAccess = response.accessLevel;
        await store.dispatch("auth/logIn", {
          token: this.userToken
        });
        //adding this temporarily as server side doesn't set the cookies
        const tokenExpireDate = new Date();
        const expireTimeInSeconds = convertToSeconds(response.expiresIn);
        tokenExpireDate.setSeconds(
          tokenExpireDate.getSeconds() + expireTimeInSeconds
        );

        cookie.set("accessToken", response.accessToken, tokenExpireDate);
        cookie.set("tokenExpireDate", tokenExpireDate, tokenExpireDate);
        this.loginSuccess = true;
        this.$router.push(
          this.$route.query.redirectFrom || { name: "default" }
        );
      } else {
        this.isLoginError = true;
        const errorCode = response.extensions.code;
        this.logError = getErrorMessageByErrorCode(errorCode);
      }
    },
    countDownChangedSuccess(dismissCountDownSuccess) {
      this.dismissCountDownSuccess = dismissCountDownSuccess;
    },
    setLanguage(locale) {
      this.lan = locale;
      i18n.locale = locale;
      window.localStorage.setItem("locale", locale);
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-gradient-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-white p-4">
                  <h5 class="text-white">{{ $t("login.welcome.title") }}</h5>
                  <p>{{ $t("login.welcome.description") }}</p>
                </div>
              </div>
              <div class="col-5 align-self-center">
                <img
                  src="@/assets/images/login-image.png"
                  alt
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <div class="avatar-md profile-user-wid mb-4">
                <span class="avatar-title rounded-circle bg-light">
                  <img src="@/assets/images/Redem Icon.png" alt height="38" />
                </span>
              </div>
            </div>

            <b-alert
              v-model="loginSuccess"
              class="mt-3"
              variant="success"
              dismissible
              >{{ $t("login.alert.success") }}</b-alert
            >

            <b-alert
              v-model="isLoginError"
              variant="danger"
              class="mt-3"
              dismissible
            >
              {{ logError }}
            </b-alert>

            <!-- Success Msg On Activation -->
            <b-alert
              :show="dismissCountDownSuccess"
              class="mb-3 m-2"
              variant="success"
              dismissible
              @dismissed="dismissCountDownSuccess = 0"
              @dismiss-count-down="countDownChangedSuccess"
            >
              {{ $t("login.alert.activation") }}
            </b-alert>

            <b-form class="p-2" @submit.prevent="clickLoginSubmit">
              <b-form-group
                id="input-group-1"
                :label="this.$t('login.form.email.label')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="email"
                  type="text"
                  :placeholder="this.$t('login.form.email.placeholder')"
                  :class="{ 'is-invalid': submit && $v.email.$error }"
                ></b-form-input>
                <div v-if="submit && $v.email.$error" class="invalid-feedback">
                  <span v-if="!$v.email.required">{{
                    $t("login.form.email.requiredErrorMsg")
                  }}</span>
                </div>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                :label="this.$t('login.form.password.label')"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="password"
                  type="password"
                  :placeholder="this.$t('login.form.password.placeholder')"
                  :class="{ 'is-invalid': submit && $v.password.$error }"
                ></b-form-input>
                <div
                  v-if="submit && $v.password.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.password.required">{{
                    $t("login.form.password.requiredErrorMsg")
                  }}</span>
                </div>
              </b-form-group>

              <div class="mt-3">
                <b-button type="submit" variant="success" class="btn-block">{{
                  $t("login.form.loginButton")
                }}</b-button>
              </div>
              <div class="mt-4 text-center">
                <router-link tag="a" to="/forgot-password" class="text-muted">
                  <i class="mdi mdi-lock mr-1"></i>
                  {{ $t("login.form.forgetPasswordButton") }}
                </router-link>
              </div>
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <!-- <p>
            {{ $t("login.footer.text1") }}
            <router-link
              tag="a"
              to="/register"
              class="font-weight-medium text-info"
              >{{ $t("login.footer.text2") }}</router-link
            >
          </p> -->
          <p>
            © {{ new Date().getFullYear() }} Redem GmbH | made with
            <i class="mdi mdi-heart text-danger"></i> in Austria
          </p>
        </div>

        <!-- language Selector -->
        <div class="mt-3 text-center">
          <span
            class="mr-2"
            @click="setLanguage(languages[0].language)"
            style="cursor: pointer"
          >
            <img class="mr-1" :src="languages[0].flag" height="10" />
            {{ languages[0].title }}
          </span>
          &bull;
          <span
            class="ml-2"
            @click="setLanguage(languages[1].language)"
            style="cursor: pointer"
          >
            <img class="mr-1" :src="languages[1].flag" height="10" />
            {{ languages[1].title }}
          </span>
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style lang="scss" module></style>
